import { mixinActions, mixinMutations, mixinState } from '../../mixins/vuexCRUD'

const getters = {
  driverLeaves: (state) => state.items,
}

const state = {
  ...mixinState(),
  path: '/driverLeaves',
}

const mutations = mixinMutations()

const actions = mixinActions()

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
