<template>
  <v-snackbar v-model="errorBar" centered shaped top color="error">
    {{ message }}
    <template v-slot:action="{ attrs }">
      <v-btn color="white" text v-bind="attrs" @click="errorBar = false"> Close </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  data() {
    return {}
  },
  computed: {
    errorBar: {
      get() {
        return this.$store.getters.showError
      },
      set(value) {
        if (!value) {
          this.$store.dispatch('setError', null)
        }
      },
    },
    message() {
      return this.$store.getters.errorMessage
    },
  },
  watch: {
    errorBar(val) {
      if (val && this.$store.getters.fatalError && this.$route.path !== '/login') {
        this.$store.dispatch('auth/logout')
        this.$router.push('/login')
      }
    },
  },
}
</script>
