import { mixinActions, mixinMutations, mixinState } from "../../mixins/vuexCRUD"

//const path = '/truckStatus'

const getters = {
  truckStatus: (state) => state.items,
}

const state = {
  ...mixinState(),
  path: '/truckStatus'
}

const mutations = mixinMutations()

const actions = mixinActions()
 

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}