<template>
  <div class="login">
    <template>
      <v-snackbar v-model="snackbar" absolute centered shaped top color="primary" outlined>
        Please enter e-mail and password to login.
      </v-snackbar>
    </template>
    <v-container fluid fill-height>
      <v-row align="center" justify="center">
        <!-- form with validation -->
        <validation-observer ref="observer" v-slot="{ handleSubmit }">
          <v-form @submit.prevent="handleSubmit(submit)">
            <v-card class="elevation-12" width="320px" style="background-color: rgba(255, 255, 255, 0.7)">
              <v-card-text>
                <!-- email input with validation -->
                <validation-provider v-slot="{ errors }" name="E-mail" rules="required|email">
                  <v-text-field
                    v-model="email"
                    :error-messages="errors"
                    required
                    class="mt-6"
                    light
                    prepend-icon="mdi-email"
                    label="E-mail"
                    type="text"
                  />
                </validation-provider>

                <!-- password input with validation -->
                <validation-provider v-slot="{ errors }" name="Password" rules="required">
                  <v-text-field
                    v-model="password"
                    :error-messages="errors"
                    required
                    light
                    prepend-icon="mdi-lock"
                    label="Password"
                    type="password"
                  />
                </validation-provider>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn class="pa-6" color="secondary" dark type="submit"> Login </v-btn>
              </v-card-actions>
              <v-btn color="secondary" fab x-large dark top right absolute @click="snackbar = true">
                <v-icon>mdi-account</v-icon>
              </v-btn>
            </v-card>
          </v-form>
        </validation-observer>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { required, email } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'

setInteractionMode('eager')

extend('required', {
  ...required,
  message: '{_field_} can not be empty',
})

extend('email', {
  ...email,
  message: 'E-mail must be valid',
})

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      email: '',
      password: '',
      snackbar: false,
      isLoading: false,
    }
  },
  computed: {
    loggedIn() {
      return this.$store.getters['auth/loggedIn']
    },
  },
  created() {
    this.$vuetify.theme.dark = false
    if (this.loggedIn) {
      this.$router.push('/')
    }
  },
  methods: {
    submit() {
      //this.$refs.observer.validate()
      if (this.isLoading) return
      this.isLoading = true

      this.$store
        .dispatch('auth/login', { email: this.email, password: this.password })
        .then(() => {
          this.$router.push('/')
        })
        .catch((err) => this.$store.dispatch('setError', err))
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>

<style>
.login {
  background: url('../assets/background.jpg') repeat center center fixed;
  background-size: cover;
  width: 100%;
  height: 100%;
}
</style>
