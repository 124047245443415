import axios from 'axios'
import { dbServer } from '../store/dbSettings'

export const mixinGetters = () => {
  return {
    filtered: (state) => state.filtered,
  }
}

export const mixinState = () => {
  return {
    items: [],
    filtered: [],
    path: '',
    dbServer: dbServer,
  }
}

export const mixinMutations = () => {
  return {
    GET_FILTERED(state, items) {
      state.filtered = items.slice()
    },
    GET_ITEMS(state, items) {
      state.items = items.slice()
    },
    DELETE_ITEM(state, item) {
      var index = state.items.findIndex((elem) => {
        return elem.id === item.id
      })
      state.items.splice(index, 1)
    },
    ADD_ITEM(state, item) {
      state.items.push(item)
    },
    UPDATE_ITEM(state, item) {
      var index = state.items.findIndex((elem) => {
        return elem.id === item.id
      })
      if (index !== -1) {
        state.items.splice(index, 1, item)
      }
    },
  }
}

export const mixinActions = () => {
  return {
    getItems({ commit, state }, data) {
      return new Promise((resolve, reject) => {
        let commitPath
        if (data && data._filtered) {
          commitPath = 'GET_FILTERED'
        } else {
          commitPath = 'GET_ITEMS'
        }
        if (data) delete data._filtered
        axios
          .get(state.dbServer + state.path, { params: data })
          .then((response) => response.data)
          .then((items) => {
            commit(commitPath, items)
            resolve()
          })
          .catch((err) => {
            reject(err)
            //dispatch("setError", err, { root: true })
          })
      })
    },
    deleteItem({ commit, state }, data) {
      return new Promise((resolve, reject) => {
        axios
          .delete(state.dbServer + state.path + '/' + data.id)
          .then((response) => response.data)
          .then((deleted) => {
            //FIXME: salje delete zato vrati delete
            commit('DELETE_ITEM', deleted)
            resolve(data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    addItem({ commit, state }, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(state.dbServer + state.path, data)
          .then((response) => response.data)
          .then((added) => {
            commit('ADD_ITEM', added)
            resolve(added)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    updateItem({ commit, state }, data) {
      return new Promise((resolve, reject) => {
        var id = data.id
        delete data.id
        axios
          .put(state.dbServer + state.path + '/' + id, data)
          .then((response) => response.data)
          .then((updated) => {
            commit('UPDATE_ITEM', updated)
            resolve(updated)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
  }
}
