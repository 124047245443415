// const path = '/roles'
import { mixinActions, mixinMutations, mixinState } from "../../mixins/vuexCRUD"

const getters = {
  roles: (state) => state.items,
}

const state = {
  ...mixinState(),
  path: '/roles'
}

const mutations = mixinMutations()

const actions = mixinActions()


export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}