const getters = {
  errorMessage: (state) => {
    if (state.error) {
      return state.error?.response?.data?.message || state.error?.message || state.error.toString()
    }
    return ''
  },
  showError: (state) => state.error !== null,
  fatalError: (state) => {
    if (state?.error?.response?.status) {
      let status = state.error.response.status
      return !(status === 401 || status === 400)
    }
    return true
  },
}

const state = () => ({
  error: null,
})

const mutations = {
  SET_ERROR(state, error) {
    state.error = error
  },
}

const actions = {
  setError({ commit }, error) {
    commit('SET_ERROR', error)
  },
}

export default {
  namespaced: false,
  state,
  mutations,
  actions,
  getters,
}
