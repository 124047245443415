import { mixinActions, mixinMutations, mixinState } from "../../mixins/vuexCRUD"

// const path = '/driverContactInfo'

const getters = {
  driverContactInfo: (state) => state.items,
  getContactsByDriverId: (state) => (id) => {
    return state.items.filter(item => item.driverId === id)
  }
}

const state = {
  ...mixinState(),
  path: '/driverContactInfo'
}

const mutations = mixinMutations()

const actions = mixinActions()


export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}