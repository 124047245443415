import io from 'socket.io-client'
import { socketServer } from '../dbSettings'

const getters = {
  isConnected: (state) => state.socket?.connected,
}

const state = () => ({
  socket: '',
  boardRoom: false,
  trackingRoom: false,
})

const mutations = {
  JOIN_BOARD_ROOM(state, room) {
    state.socket.emit('join-room', room)
    state.boardRoom = room
  },
  JOIN_TRACKING_ROOM(state, room) {
    state.socket.emit('join-room', room)
    state.trackingRoom = room
  },
  LEAVE_BOARD_ROOM(state) {
    if (state.boardRoom) {
      state.socket.emit('leave-room', state.boardRoom)
      state.boardRoom = false
    }
  },
  LEAVE_TRACKING_ROOM(state) {
    if (state.trackingRoom) {
      state.socket.emit('leave-room', state.trackingRoom)
      state.trackingRoom = false
    }
  },
}

const actions = {
  createSocket({ dispatch, state }) {
    // Socket connection
    let user = this.getters['auth/activeUser']
    let array = user.token.split(' ')
    const token = array[1]
    const tokenType = array[0]
    state.socket = io(socketServer, {
      rejectUnauthorized: false,
      reconnection: true,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      reconnectionAttempts: 10,
      auth: { token, tokenType },
    })
    state.socket.on('receive-update', (data) => {
      dispatch('receiveUpdate', data)
    })
  },
  closeSocket({ state }) {
    // state.socket?.close()
    if (state.socket && state.socket.close) state.socket.close()
  },
  receiveUpdate({ dispatch }, messageString) {
    // {
    //     "type": "loadInfo",
    //     "method": "add/update/delete/reload",
    //     "data": {
    //                 "id":"104",
    //                 "driverId":"1",
    //                 "loadNo":"#loadNo1",
    //                 "broker":"Broker1",
    //                 "address":"Kragujevac",
    //                 "dateFrom":"2021-12-01T10:00:00.000Z",
    //                 "dateTo":"2021-12-01T22:00:00.000Z",
    //                 "loadTypeColor":"error",
    //                 "loadTypeId":4,
    //                 "loadTypeText":"Pickup",
    //                 "range":true,
    //                 "loadNotes":null
    //             ]
    // }
    let message = JSON.parse(messageString)
    // console.log(message)
    if (message.type === 'board' && message.boardId === this.getters['activeBoard']) {
      dispatch('updateDashboardField', message.data, { root: true })
    } else if (message.type === 'loadNote') {
      let prefix = 'loadInfo/'
      let postfix = 'Notes'
      dispatch(prefix + message.method + postfix, message.data, { root: true })
    } else if (message.type === 'loadInfo') {
      let prefix = 'loadInfo/'
      let postfix = 'LoadInfo'
      dispatch(prefix + message.method + postfix, message.data, { root: true })
    }
  },
  // joinRoom({ state }, room) {
  //   console.log('join ' + room)
  //   state.socket.emit('join-room', room)
  // },
  // leaveRoom({ state }, room) {
  //   console.log('leave ' + room)
  //   state.socket.emit('leave-room', room)
  // },
  joinBoardRoom({ state, commit }, room) {
    let newRoom = 'board-' + room
    if (state.boardRoom !== newRoom) {
      commit('LEAVE_BOARD_ROOM')
      commit('JOIN_BOARD_ROOM', newRoom)
    }
  },
  joinTrackingRoom({ state, commit }, room) {
    let newRoom = 'tracking-' + room
    if (state.trackingRoom !== newRoom) {
      commit('LEAVE_TRACKING_ROOM')
      commit('JOIN_TRACKING_ROOM', newRoom)
    }
  },
  leaveBoardRoom({ commit }) {
    commit('LEAVE_BOARD_ROOM')
  },
  leaveTrackingRoom({ commit }) {
    commit('LEAVE_TRACKING_ROOM')
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
