import axios from 'axios'
import { dbServer, lsName } from '../dbSettings'

const path = '/auth/signin'

const user = JSON.parse(localStorage.getItem(lsName))
const initialState = user ? { loggedIn: true, user } : { loggedIn: false, user: null }

const getters = {
  loggedIn: (state) => state.loggedIn,
  activeUser: (state) => state.user,
  isAdmin: (state) => {
    if (typeof state.user.roleId !== 'number') return false
    return state.user.roleId === 3
  },
  isEditor: (state) => {
    if (typeof state.user.roleId !== 'number') return false
    return state.user.roleId >= 2
  },
}

const state = initialState

const mutations = {
  SET_ACTIVE(state, user) {
    localStorage.setItem(lsName, JSON.stringify(user))
    state.user = user
  },
  LOGIN_SUCCESS(state, user) {
    localStorage.setItem(lsName, JSON.stringify(user))
    state.loggedIn = true
    state.user = user
  },
  LOGIN_FAILURE(state) {
    localStorage.removeItem(lsName)
    state.loggedIn = false
    state.user = null
  },
  LOGOUT(state) {
    localStorage.removeItem(lsName)
    state.loggedIn = false
    state.user = null
  },
  REFRESH_TOKEN(state, accessToken) {
    let user = JSON.parse(localStorage.getItem(lsName))
    user.token = accessToken
    localStorage.setItem(lsName, JSON.stringify(user))
    state.loggedIn = true
    state.user = { ...state.user, token: accessToken }
  },
}

const actions = {
  login({ commit }, user) {
    return new Promise((resolve, reject) => {
      axios
        .post(dbServer + path, {
          email: user.email,
          password: user.password,
        })
        .then((response) => response.data)
        .then((activeUser) => {
          if (!('token' in activeUser && 'boardId' in activeUser)) {
            throw new Error('Fatal error: Corrupted data!')
          }
          commit('LOGIN_SUCCESS', activeUser)
          //dispatch('setActiveBoard', activeUser.boardId, { root: true })
        })
        .then(() => {
          resolve()
        })
        .catch((err) => {
          commit('LOGIN_FAILURE')
          reject(err)
          //dispatch("setError", err, { root: true })
        })
    })
  },
  logout({ commit }) {
    commit('LOGOUT')
  },
  refreshToken({ commit }, accessToken) {
    return new Promise((resolve) => {
      commit('REFRESH_TOKEN', accessToken)
      resolve()
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
