<template>
  <v-app>
    <v-main>
      <Error />
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import Error from './components/ErrorSnackbar.vue'

export default {
  name: 'App',
  components: {
    Error,
  },
  data: () => ({}),
}
</script>
<style>
.v-application.theme--dark {
  background-color: var(--v-background-base) !important;
}
</style>
