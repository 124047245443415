import { mixinActions, mixinMutations, mixinState } from "../../mixins/vuexCRUD"
//const path = '/dispatchers' 

const getters = {
  dispatchers: (state) => state.items,
  fullName: (state) => {
    return state.items.map((item) => {
      var obj = {}
      obj.id = item.id
      obj.text = item.firstName + " " + item.lastName
      return obj
    })      
  },
}

const state = {
  ...mixinState(),
  path: '/dispatchers'
}

const mutations = mixinMutations()

const actions = mixinActions()


export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}