import { mixinActions, mixinMutations, mixinState, mixinGetters } from '../../mixins/vuexCRUD'

const getters = {
  ...mixinGetters(),
  trucks: (state) => state.items,
}

const state = {
  ...mixinState(),
  path: '/trucks',
}

const mutations = mixinMutations()

const actions = mixinActions()

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
