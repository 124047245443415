//const path = '/users'
import axios from "axios"
import { mixinActions, mixinMutations, mixinState } from "../../mixins/vuexCRUD"

const pass = '/password'

const getters = {
  users: (state) => state.items,
}

const state = {
  ...mixinState(),
  path: '/users'
}

const mutations = mixinMutations()

const actions = {
  ...mixinActions(),
  addPassword: ({ state }, data) => {
    return new Promise((resolve, reject) => {
      axios.post(state.dbServer + pass, data)
      .then(() => {
        resolve()
      })
      .catch((err) => reject(err))
    })
  },
  updatePassword: ({ state }, data) => {
    return new Promise((resolve, reject) => {
      var id = data.id
      delete data.id
      axios.put(state.dbServer + pass + "/" + id, data)
      .then(() => {
        resolve()
      })
      .catch(err => reject(err))
    })
  },
}


export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}