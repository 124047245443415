import axios from 'axios'
// const path = '/loadInfo'

import { mixinActions, mixinMutations, mixinState } from '../../mixins/vuexCRUD'
import { jsonServer } from '../dbSettings'

const getters = {
  loadInfo: (state) => state.items,
  trackingLog: (state) => state.trackingLog,
}

const state = {
  ...mixinState(),
  path: '/loadInfo',
  trackingLog: [],
  isLoading: false,
}

const mutations = {
  ...mixinMutations(),
  SET_TRACKING_LOG(state, data) {
    state.trackingLog = data
  },
  UPDATE_NOTES(state, note) {
    let tIndex = state.trackingLog.findIndex((item) => item.id === note.loadInfoId)
    if (tIndex === -1) return
    let nIndex = state.trackingLog[tIndex].loadNotes.findIndex((item) => item.id === note.id)
    if (nIndex === -1) return
    state.trackingLog[tIndex].loadNotes.splice(nIndex, 1, note)
  },
  ADD_NOTES(state, note) {
    let tIndex = state.trackingLog.findIndex((item) => item.id === note.loadInfoId)

    if (tIndex === -1) return

    if (!state.trackingLog[tIndex].loadNotes) {
      state.trackingLog[tIndex].loadNotes = []
    }
    let nIndex = state.trackingLog[tIndex].loadNotes.findIndex((item) => item.id === note.id)
    if (nIndex === -1) {
      state.trackingLog[tIndex].loadNotes.push(note)
    }
  },
  DELETE_NOTES(state, note) {
    let tIndex = state.trackingLog.findIndex((item) => item.id === note.loadInfoId)
    if (tIndex === -1) return
    let nIndex = state.trackingLog[tIndex].loadNotes.findIndex((item) => item.id === note.id)
    if (nIndex === -1) return
    state.trackingLog[tIndex].loadNotes.splice(nIndex, 1)
  },
  UPDATE_LOAD_INFO(state, loadInfo) {
    let tIndex = state.trackingLog.findIndex((item) => item.id === loadInfo.id)
    if (tIndex === -1) return
    loadInfo.loadNotes = state.trackingLog[tIndex].loadNotes
    state.trackingLog.splice(tIndex, 1, loadInfo)
  },
  ADD_LOAD_INFO(state, loadInfo) {
    let tIndex = state.trackingLog.findIndex((item) => item.id === loadInfo.id)
    if (tIndex !== -1) return
    state.trackingLog.push(loadInfo)
  },
  DELETE_LOAD_INFO(state, loadInfo) {
    let tIndex = state.trackingLog.findIndex((item) => {
      return item.id === loadInfo.id
    })
    if (tIndex === -1) return
    state.trackingLog.splice(tIndex, 1)
  },
}

const actions = {
  ...mixinActions(),
  getTrackingLog: ({ state, commit }, data) => {
    if (state.isLoading) {
      return Promise.resolve()
    }
    if (!(data.dateFrom || data.driverId)) {
      commit('SET_TRACKING_LOG', [])
      return Promise.resolve()
    }

    if (jsonServer) {
      data.dateFrom = data.dateFrom.substr(0, 10)
    }

    //To hide items before loading...
    commit('SET_TRACKING_LOG', [])

    state.isLoading = true

    return new Promise((resolve, reject) => {
      axios
        .get(state.dbServer + state.path, { params: data })
        .then((response) => response.data)
        .then((trackingLogs) => {
          commit('SET_TRACKING_LOG', trackingLogs)
          resolve()
        })
        .catch((err) => reject(err))
        .finally(() => {
          state.isLoading = false
        })
    })
  },
  updateNotes: ({ commit }, data) => {
    commit('UPDATE_NOTES', data)
  },
  addNotes: ({ commit }, data) => {
    commit('ADD_NOTES', data)
  },
  deleteNotes: ({ commit }, data) => {
    commit('DELETE_NOTES', data)
  },
  updateLoadInfo: ({ commit }, data) => {
    commit('UPDATE_LOAD_INFO', data)
  },
  addLoadInfo: ({ commit }, data) => {
    commit('ADD_LOAD_INFO', data)
  },
  deleteLoadInfo: ({ commit }, data) => {
    commit('DELETE_LOAD_INFO', data)
  },
  reloadLoadInfo: ({ dispatch, rootGetters }) => {
    let item = rootGetters.dashboardDay
    if (typeof item === 'string') return
    // Must change because time offset
    // let dateFrom = new Date(item - new Date().getTimezoneOffset() * 60000).toISOString()
    let dateFrom = item.toISOString()
    let params = { dateFrom: dateFrom, driverId: rootGetters.dashboardItem?.driverId }
    dispatch('getTrackingLog', params)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
