import { mixinActions, mixinMutations, mixinState } from "../../mixins/vuexCRUD"
// const path = '/boards'

const getters = {
  boards: (state) => state.items,
}

const state = {
  ...mixinState(),
  path: '/boards'
}

const mutations = mixinMutations()

const actions = mixinActions()


export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}