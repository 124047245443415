// const path = '/trailerStatus'
import { mixinActions, mixinMutations, mixinState } from "../../mixins/vuexCRUD"

const getters = {
  trailerStatus: (state) => state.items,
}

const state = {
  ...mixinState(),
  path: '/trailerStatus'
}

const mutations = mixinMutations()

const actions = mixinActions()


export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}