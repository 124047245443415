import axios from 'axios'
import { dbServer } from './dbSettings'

import dateF from '../services/date.service'

const getters = {
  activeDate: (state) => {
    return state.activeDate
  },
  activeDay: (state) => {
    if (typeof state.activeDate === 'object') {
      //return state.activeDate.getUTCDay()
      return (((state.activeDate.getUTCDay() - 1) % 7) + 7) % 7
    } else return -1
  },
  activeWeek: (state) => {
    return dateF.getWeek(state.activeDate)
  },
  activeBoard: (state) => state.activeBoard,
  loadingDashboard: (state) => {
    return state.loadingDashboard
  },
  dashboard: (state) => state.dashboard,
  dashboardItem: (state) => state.dashboardItem,
  dashboardIndex: (state) => state.dashboardIndex,
  dashboardDay: (state) => dateF.selectedDay(state.activeDate, state.dashboardIndex),
  dashboardSearch: (state) => state.dashboardSearch,
}

const actions = {
  setDashboardSearch: ({ commit }, data) => {
    commit('SET_DASHBOARD_SEARCH', data)
  },
  setActiveDate: ({ dispatch, commit, state }, date) => {
    return new Promise((resolve, reject) => {
      var oldDate = state.activeDate
      commit('SET_ACTIVE_DATE', date)

      if (typeof oldDate !== 'object' || !dateF.isEqualWeek(state.activeDate, oldDate)) {
        dispatch('reloadDashboard')
          .then(() => resolve())
          .catch((err) => reject(err))
      } else {
        resolve()
      }
    })
  },
  setActiveBoard: ({ dispatch, commit, state }, value) => {
    return new Promise((resolve, reject) => {
      if (!value) value = 1
      var oldBoard = state.activeBoard
      commit('SET_ACTIVE_BOARD', value)
      if (oldBoard !== state.activeBoard) {
        dispatch('reloadDashboard')
          .then(() => {
            // if (typeof oldBoard === 'number') dispatch('socket/leaveRoom', 'board-' + oldBoard)
            dispatch('socket/joinBoardRoom', value)
            resolve()
          })
          .catch((err) => {
            reject(err)
          })
      } else {
        resolve()
      }
    })
  },
  updateDashboardField: ({ commit }, data) => {
    commit('UPDATE_DASHBOARD_FIELD', data)
  },
  dashboardUpdate: ({ dispatch }, data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(dbServer + '/dashboardUpdate', data)
        .then(() => {
          dispatch('updateDashboardField', data)
          resolve()
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  resetDashboard: ({ commit, dispatch }) => {
    // leave any tracking room
    dispatch('socket/leaveTrackingRoom')
    commit('SET_LOADING_DASHBOARD', false)
    commit('SET_DASHBOARD_ITEM', {})
    commit('SET_DASHBOARD_INDEX', '')
  },
  resetActiveBoard: ({ commit }) => {
    commit('SET_ACTIVE_BOARD', '')
  },
  reloadDashboard: ({ dispatch, commit, state }) => {
    return new Promise((resolve, reject) => {
      let test = state.activeDate && state.activeDate.getUTCDay && typeof state.activeBoard === 'number'
      if (!state.loadingDashboard && test) {
        commit('SET_DASHBOARD', [])
        dispatch('resetDashboard')
        commit('SET_LOADING_DASHBOARD', true)
        // const params = {
        //   boardId: state.activeBoard,
        //   year: state.activeDate.getFullYear(),
        //   week: dateF.getWeek(state.activeDate)
        // }
        // console.log(params)

        let week = dateF.getWeek(state.activeDate)
        axios
          .get(dbServer + '/dashboardTable', {
            params: {
              boardId: state.activeBoard,
              year: week.year,
              week: week.week,
            },
          })
          .then((response) => {
            commit('SET_DASHBOARD', response.data)
            resolve()
          })
          .catch((err) => {
            reject(err)
          })
          .finally(() => {
            // dispatch('resetDashboard')
            commit('SET_LOADING_DASHBOARD', false)
          })
      } else {
        dispatch('resetDashboard')
        resolve()
      }
    })
  },
}

const state = () => ({
  activeDate: '',
  activeBoard: '',
  loadingDashboard: false,
  dashboard: [],
  dashboardItem: {},
  dashboardIndex: '',
  dashboardSearch: '',
})

const mutations = {
  SET_DASHBOARD_SEARCH(state, data) {
    state.dashboardSearch = data
  },
  UPDATE_DASHBOARD_FIELD(state, data) {
    // {
    //   "id": "2021-45-1",
    //   "board": 1,
    //   "change": "note2",
    //   "value": "Proba notes za sredu"
    // },
    let index = state.dashboard.findIndex((element) => element.id === data.id)
    if (index !== -1) {
      state.dashboard[index][data.change] = data.value
    }
  },
  SET_DASHBOARD_INDEX(state, index) {
    state.dashboardIndex = index
  },
  SET_DASHBOARD_ITEM(state, item) {
    state.dashboardItem = item
  },
  SET_LOADING_DASHBOARD(state, val) {
    state.loadingDashboard = val
  },
  SET_DASHBOARD(state, value) {
    state.dashboard = value.slice()
  },
  SET_ACTIVE_DATE(state, date) {
    if (typeof date === 'string') {
      const [year, month, day] = date.split('-')
      state.activeDate = new Date(Date.UTC(year, month - 1, day))
    } else {
      state.activeDate = date
    }
  },
  SET_ACTIVE_BOARD(state, board) {
    state.activeBoard = board
  },
}

export default {
  namespaced: false,
  state,
  mutations,
  actions,
  getters,
}
