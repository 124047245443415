import { mixinActions, mixinMutations, mixinState } from '../../mixins/vuexCRUD'
// const path = '/boards'

const getters = {
  assetsAllocate: (state) => state.items,
}

const state = {
  ...mixinState(),
  path: '/assetsAllocate',
}

const mutations = mixinMutations()

const actions = mixinActions()

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
