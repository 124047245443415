import { mixinActions, mixinMutations, mixinState } from '../../mixins/vuexCRUD'

const getters = {
  loadNotes: (state) => state.items
}

const state = {
  ...mixinState(),
  path: '/loadNotes'
}

const mutations = mixinMutations()

const actions = mixinActions()

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
