const dateF = {
  getWeek: (date) => {
    // var date = new Date(d.getTime())
    // date.setUTCHours(0, 0, 0, 0)
    // // Thursday in current week decides the year.
    // date.setUTCDate(date.getUTCDate() + 3 - ((date.getUTCDay() + 6) % 7))
    // // January 4 is always in week 1.
    // var week1 = new Date(date.getUTCFullYear(), 0, 4)
    // // Adjust to Thursday in week 1 and count number of weeks from date to week1.

    //var d = new Date(Date.UTC(this.getFullYear(), this.getMonth(), this.getDate()));
    var d = new Date(date.getTime())
    var dayNum = d.getUTCDay() || 7
    d.setUTCDate(d.getUTCDate() + 4 - dayNum)
    var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1))

    // return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000 - 3 + ((week1.getUTCDay() + 7) % 7)) / 7)
    //return Math.ceil(((d - yearStart) / 86400000 + 1) / 7)
    return {
      year: yearStart.getUTCFullYear(),
      week: Math.ceil(((d - yearStart) / 86400000 + 1) / 7),
    }
  },
  isEqualWeek: (date1, date2) => {
    let week1 = dateF.getWeek(date1)
    let week2 = dateF.getWeek(date2)
    return week1.year === week2.year && week1.week === week2.week
  },
  formatBirthday: (date) => {
    let obj = date
    if (typeof date === 'string') {
      date = date.substring(0, 10)
      const [year, month, day] = date.split('-')
      obj = new Date(Date.UTC(year, month - 1, day))
    }
    let options = { year: 'numeric', month: 'long', day: 'numeric' }
    return dateF.formatDate(obj, options)
  },
  formatDate: (date, options, localTime) => {
    if (!date) return ''

    let obj = date
    if (typeof date === 'string') {
      obj = new Date(date)
    }

    if (!options) options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
    if (!localTime) options.timeZone = 'UTC'
    return obj.toLocaleDateString(undefined, options)
  },
  formatWithoutDay: (date) => {
    let options = { year: 'numeric', month: 'long', day: 'numeric' }
    return dateF.formatDate(date, options)
  },
  formatFullDate: (date, localTime) => {
    let options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' }
    return dateF.formatDate(date, options, localTime)
  },
  formatFullDateWithSeconds: (date, localTime) => {
    let options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    }
    return dateF.formatDate(date, options, localTime)
  },
  formatTime: (timeString) => {
    if (!timeString) return ''

    let hourEnd = timeString.indexOf(':')
    let H = +timeString.substr(0, hourEnd)
    let h = H % 12 || 12
    let ampm = H < 12 || H === 24 ? ' AM' : ' PM'
    return h + timeString.substr(hourEnd) + ampm
  },
  selectedDay: (activeDate, index) => {
    if (!(typeof activeDate === 'object' && typeof index === 'number')) {
      return ''
    }

    var startDay = new Date(activeDate.getTime())
    startDay.setUTCDate(startDay.getUTCDate() - activeDate.getUTCDay())
    // zbog toga sto krece od nedelje se vraca na prethodnu i dodaje +1 na index
    if (activeDate.getUTCDay() === 0) {
      startDay.setUTCDate(startDay.getUTCDate() - 7)
    }
    startDay.setUTCDate(startDay.getUTCDate() + index + 1)

    return startDay
  },
}

export default dateF
