import Vue from 'vue'
import Vuex from 'vuex'
import trucks from './modules/trucks'
import trailers from './modules/trailers'
import dispatchers from './modules/dispatchers'
import drivers from './modules/drivers'
import driverContactInfo from './modules/driverContactInfo'
import boards from './modules/boards'
import users from './modules/users'
import roles from './modules/roles'
import activeData from './activeData'
import truckStatus from './modules/truckStatus'
import trailerStatus from './modules/trailerStatus'
import loadInfo from './modules/loadInfo'
import loadTypes from './modules/loadTypes'
import auth from './modules/auth'
import errorData from './errorData'
import loadNotes from './modules/loadNotes'
import assetsAllocate from './modules/assetsAllocate'
import socket from './modules/socket'
import driverHos from './modules/driverHos'
import hosTypes from './modules/hosTypes'
import driverLeaves from './modules/driverLeaves'

Vue.use(Vuex)

// http://localhost:3000/loadInfo?dateFrom=2021-11-08&driverId=1

export default new Vuex.Store({
  modules: {
    trucks,
    trailers,
    dispatchers,
    drivers,
    driverContactInfo,
    boards,
    users,
    roles,
    activeData,
    truckStatus,
    trailerStatus,
    loadInfo,
    loadTypes,
    auth,
    errorData,
    loadNotes,
    assetsAllocate,
    socket,
    driverHos,
    hosTypes,
    driverLeaves,
  },
})
