import axios from 'axios'
import { lsName, loginPath, tokenRefreshPath, dbServer, jsonServer } from '../store/dbSettings'

const currentAppUser = (user) => {
  if (user.id && user.email) return user.id + ' - ' + user.email
  else return ''
}

const authHeader = (user) => {
  if (user && user.token) {
    return user.token
  } else {
    return ''
  }
}

const getUser = () => {
  return JSON.parse(localStorage.getItem(lsName))
}

export default function intercept(store) {
  axios.interceptors.request.use(
    (config) => {
      let user = getUser()
      config.headers.Authorization = authHeader(user)
      // config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
      // config.headers['Access-Control-Allow-Origin'] = '*'
      if (user) {
        config.headers['currentAppUser'] = currentAppUser(user)
      }
      return config
    },
    (err) => Promise.reject(err)
  )

  axios.interceptors.response.use(
    (response) => response,
    async (err) => {
      const originalConfig = err.config
      let fullPath = dbServer + loginPath
      if (originalConfig.url !== fullPath && err.response) {
        // Access Token was expired
        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true

          try {
            let data = {}
            let user = getUser()
            data.refreshToken = user?.refreshToken

            if (jsonServer) {
              data.id = user?.id
            }

            const rs = await axios.post(dbServer + tokenRefreshPath, data)

            const { token } = rs.data
            await store.dispatch('auth/refreshToken', token)

            return axios(originalConfig)
          } catch (_error) {
            //store.dispatch('setError', _error)
            return Promise.reject(_error)
          }
        }
      }
      return Promise.reject(err)
    }
  )
}
