import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)
//green 2CDB00
//primary 193549
const vuetify = new Vuetify({
  theme: {
    //dark: true,
    options: { customProperties: true },
    themes: {
      light: {
        secondary: '#0d355c',
        primary: '#0d355c',
        error: '#ff3030',
        warning: '#ebc51e',
        success: '#28c728',
      },
      dark: {
        background: '#122738',
        primary: '#4086b8',
        secondary: '#193549',
        // error: '#ff628c',
        // warning: '#ff9d00',
        // info: '#0096d4',
        // success: '#9afd97',
        error: '#c8628d',
        warning: '#c89d00',
        info: '#0096c8',
        success: '#32c832',
      },
    },
  },
})

export default vuetify
