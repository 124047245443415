import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"login"},[[_c(VSnackbar,{attrs:{"absolute":"","centered":"","shaped":"","top":"","color":"primary","outlined":""},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" Please enter e-mail and password to login. ")])],_c(VContainer,{attrs:{"fluid":"","fill-height":""}},[_c(VRow,{attrs:{"align":"center","justify":"center"}},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c(VForm,{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c(VCard,{staticClass:"elevation-12",staticStyle:{"background-color":"rgba(255, 255, 255, 0.7)"},attrs:{"width":"320px"}},[_c(VCardText,[_c('validation-provider',{attrs:{"name":"E-mail","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c(VTextField,{staticClass:"mt-6",attrs:{"error-messages":errors,"required":"","light":"","prepend-icon":"mdi-email","label":"E-mail","type":"text"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c(VTextField,{attrs:{"error-messages":errors,"required":"","light":"","prepend-icon":"mdi-lock","label":"Password","type":"password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)})],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{staticClass:"pa-6",attrs:{"color":"secondary","dark":"","type":"submit"}},[_vm._v(" Login ")])],1),_c(VBtn,{attrs:{"color":"secondary","fab":"","x-large":"","dark":"","top":"","right":"","absolute":""},on:{"click":function($event){_vm.snackbar = true}}},[_c(VIcon,[_vm._v("mdi-account")])],1)],1)],1)]}}])})],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }